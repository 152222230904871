import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './Ido.module.css';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import copy from '../../assets/images/copy.png';
import copiedimg from '../../assets/images/copied.png';
import { getTimeLeft } from '../../utils/time';
import useNotyf from '../../hooks/useNotyf';
import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi';
import { BigNumber, Contract, ethers, utils } from 'ethers';
import presaleAbi from '../../abi/presaleAbi.json';
import tokenAbi from '../../abi/tokenAbi.json';
import routerAbi from '../../abi/routerAbi.json';
import { useSearchParams } from 'react-router-dom';
import { formatStringToTwoDecimals, convertToTrillionRepresentation } from '../../utils/format';
import { AppContext } from '../../context/AppContext';
import axios from 'axios';

const presaleAddress = '0x2bFd3Ee03b4E7EDD98b642259a3b45127E696Ec5';
const routerAddress = '0x10ed43c718714eb63d5aa57b78b54704e256024e';
const busdtAddress = '0x55d398326f99059fF775485246999027B3197955';
const wethAddress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';

const Ido = () => {
  const [loading, setloading] = useState(false);
  const [copied, setcopied] = useState(false);
  const targetDate = new Date('August 21, 2023 08:00:00 UTC');
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(targetDate));
  const [searchParams, setSearchParams] = useSearchParams();
  const notyf = useNotyf();
  const decimals = useRef();
  const { tokenInfo, setTokenInfo } = useContext(AppContext);
  let { address } = useAccount();
  const [referralLink, setReferralLink] = useState('');
  // console.log(address);

  const [depositAmt, setDepositAmt] = useState('');
  const [presaleInfo, setPresaleInfo] = useState({
    round: 0,
    ethDepositLimit: 0,
    sold: 0,
    raised: 0,
    tokenPriceInUsd: 0,
    tokenPriceInBnb: 0,
    totalSupply: 0,
    ethDepositPrice: 0,
    tokenName: '',
    tokenSymbol: '',
    minEthDeposit: 0,
    rewardsGiven: 0,
    raisedInBnb: 0,
    BnbPriceOfToken: 0,
  });
  const [referralInfo, setreferralInfo] = useState({
    rewardEarned: 0,
    referralsCount: 0,
  });
  // console.log(presaleInfo);

  const { data: signer } = useSigner();
  const provider = useProvider();
  const { chain } = useNetwork();
  const presale = new Contract(presaleAddress, presaleAbi, signer ? signer : provider);
  const router = new Contract(routerAddress, routerAbi, provider);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft(targetDate));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (chain && chain.id !== 56) {
      notyf.error('Please switch to bsc mainnet');
    } else {
      fetchPresale();
    }
  }, [chain]);

  async function getReferralDetails() {
    try {
      if (address) {
        let referralsCount = await presale.totalReferralCount();
        let rewardEarned = await presale.rewardsGiven(address);
        // console.log(referralsCount,rewardEarned);
        setreferralInfo({
          referralsCount: referralsCount.toString() || 0,
          rewardEarned: utils.formatEther(rewardEarned) || 0,
        });
      }
    } catch (error) {
      console.dir(error);
    }
  }

  useEffect(() => {
    getReferralDetails();
    if (address) {
      const referralLink = 'https://www.savior.at/' + '?ref=' + address;
      setReferralLink(referralLink);
    }
  }, [address]);

  async function fetchPresale() {
    setloading(true);
    try {
      let projectToken = await presale.projectToken();
      const token = new Contract(projectToken, tokenAbi, signer ? signer : provider);
      decimals.current = await token.decimals();

      let [totalSupply, name, symbol] = await Promise.all([token.totalSupply(), token.name(), token.symbol()]);
      totalSupply = utils.formatUnits(totalSupply, decimals.current);

      setTokenInfo({
        name,
        symbol,
        totalSupply,
        decimals: decimals.current,
      });

      let [round, ethDepositLimit, ethDepositPrice, sold, raised, minEthDeposit] = await Promise.all([
        presale.presaleRound(),
        presale.ethDepositLimit(),
        presale.ethDepositPrice(),
        presale.totalProjectTokenSold(),
        provider.getBalance(presaleAddress),
        presale.minEthDeposit(),
      ]);
      let rewardsGiven = 0;
      if (signer) {
        rewardsGiven = await presale.rewardsGiven(await signer.getAddress());
        rewardsGiven = utils.formatEther(rewardsGiven);
      }

      let tokenPriceInUsd = 0,
        priceMultiple = 1;
      try {
        tokenPriceInUsd = await router.getAmountsOut(utils.parseUnits('1', decimals.current), [projectToken, busdtAddress]);
        priceMultiple = tokenPriceInUsd = utils.formatEther(tokenPriceInUsd[1]).toNumber();
      } catch (error) {
        console.log(error, 'tokenPriceInUsd');
      }

      let tokenPriceInBnb = 0,
        BnbPriceOfToken = 0;
      try {
        tokenPriceInBnb = await router.getAmountsOut(utils.parseEther('1'), [wethAddress, projectToken]);
        BnbPriceOfToken = await router.getAmountsOut(utils.parseUnits('1', decimals.current), [projectToken, wethAddress]);
        BnbPriceOfToken = utils.formatEther(BnbPriceOfToken[1]);
        tokenPriceInBnb = utils.formatUnits(tokenPriceInBnb[1], decimals.current);
      } catch (error) {
        console.log(error, 'tokenPriceInBnb');
      }

      let BnbPrice = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${wethAddress}`);
      BnbPrice = BnbPrice.data.pairs[0].priceUsd;

      setPresaleInfo({
        round: round.toNumber(),
        ethDepositLimit: utils.formatEther(ethDepositLimit),
        sold: formatStringToTwoDecimals((Number(utils.formatUnits(sold, decimals.current)) * priceMultiple).toString()),
        raised: formatStringToTwoDecimals((Number(utils.formatEther(raised)) * BnbPrice).toString()),
        tokenPriceInUsd,
        tokenPriceInBnb,
        totalSupply,
        ethDepositPrice: utils.formatUnits(ethDepositPrice, decimals.current),
        tokenName: name,
        tokenSymbol: symbol,
        minEthDeposit: utils.formatEther(minEthDeposit),
        rewardsGiven,
        raisedInBnb: formatStringToTwoDecimals(utils.formatEther(raised)),
        BnbPriceOfToken,
      });
    } catch (error) {
      console.dir(error);
      // console.log("fetchPresale");
      notyf.error(error?.reason || error?.message);
    } finally {
      setloading(false);
    }
  }

  async function buyPresale() {
    setloading(true);
    try {
      if (!signer) {
        throw new Error('Connect your wallet');
      }

      let refferalAddress = searchParams.get('ref');
      refferalAddress = refferalAddress ? refferalAddress : '0x0000000000000000000000000000000000000000';
      const txn = await presale.depositEth(refferalAddress, {
        value: utils.parseEther(depositAmt),
      });
      const receipt = await txn.wait();
      console.log(receipt);
      notyf.success('Eth Deposit succesfull');
      setDepositAmt('');
    } catch (error) {
      console.dir(error);
      console.log('buypresale');
      notyf.error(error?.reason || error?.message);
    } finally {
      setloading(false);
      fetchPresale();
    }
  }

  async function claim() {
    setloading(true);
    try {
      const txn = await presale.claim();
      const receipt = await txn.wait();
      console.log(receipt);
    } catch (error) {
      console.dir(error);
      notyf.error(error?.reason || error?.message);
    } finally {
      setloading(false);
    }
  }

  async function getRefferalLink() {
    try {
      if (!address) {
        notyf.error('Connect your wallet');
        return;
      }
      const referralLink = 'https://www.savior.at/' + '?ref=' + address;
      setReferralLink(referralLink);
      notyf.success ('Referral Link Copied to Clipboard');
      navigator.clipboard.writeText(referralLink);
      setcopied(true)
    } catch (e) {
      console.log(e);
      notyf.error('Check if you have connected the wallet!');
    }
  }

  const handleInputChange = (event) => {
    setDepositAmt(event.target.value);
  };

  return (
    <section className={styles.container} id="ido">
      <h1 className="heading moving-color-animation">Participate in our IDO Event!</h1>

      <div className={styles.wrapper}>
        <div data-aos="fade-right">
          <h3>During our IDO event, you will gain early access to our revolutionary ecosystem, designed to empower everyone to share wealth and achieve success.</h3>

          <div className={styles.tokenInfo}>
            <span>Token Info</span>

            <p>
              Total Token Supply <span>20%</span>
            </p>
            <p>
              Soft Cap <span>200 BNB</span>
            </p>
            <p>
              Initial exchange rate (1BNB){' '}
              <span>
                {/*                 {presaleInfo.ethDepositPrice} {presaleInfo.tokenSymbol} */}
                1T SVR
              </span>
            </p>
            <p>Recommended Referral Commission</p>

            <p>
              1st Generation <span>5%</span>
            </p>
            <p>
              2nd Generation <span>2%</span>
            </p>
            <p>
              Your rewards <span>{presaleInfo.rewardsGiven} BNB</span>
            </p>
          </div>
          <div className={styles.tokenInfo}>
            <span>Referrals</span>

            <p>
              Total referrals in presale <span>{referralInfo.referralsCount}</span>
            </p>
            <p>
              My referral earning <span>{referralInfo.rewardEarned}</span>
            </p>
          </div>

          <h1 className="heading">Become an affiliate & Earn 7% as Commission!</h1>

          {referralLink && <p style={{ textAlign: 'center' }}>{referralLink}</p>}

          <div className={styles.affiliate}>
            <button onClick={getRefferalLink}>Generate</button>
            <img src={copied?copiedimg:copy} alt="copy" onClick={getRefferalLink} />
          </div>
        </div>

        <div className={styles.presale} data-aos="fade-left">
          <h1 className="heading">Presale {presaleInfo.round}</h1>
          <p>
            {/*             1 {presaleInfo.tokenName} = {presaleInfo.BnbPriceOfToken} BNB */}1 BNB = 1 Trillion {presaleInfo.tokenSymbol}
          </p>
          {/* <p>Next Stage Price = 0.723 USDT</p> */}
          <p>Sold - ${presaleInfo.sold}</p>
          <p>Raised - ${presaleInfo.raised}</p>
          <progress value={presaleInfo.raisedInBnb} max="200"></progress>
          <div className={styles.progressLabel}>
            <label>{presaleInfo.raisedInBnb} BNB</label>
            <label>200 BNB</label>
          </div>

          <input type="number" placeholder="Enter the amount (BNB)" min={0.1} max={2} onChange={handleInputChange} value={depositAmt} />
          <p>Minimum Quantity to Buy = {presaleInfo.minEthDeposit} BNB</p>
          <p>Maximum Quantity to Buy = {presaleInfo.ethDepositLimit} BNB</p>

          <div className={styles.buttons}>
            <button onClick={() => buyPresale()}>Buy</button>
            <button onClick={claim}>Claim</button>
          </div>

          <div className={styles.timer}>
            {timeLeft.split('').map((item) => {
              if (item === ':') return <p className={styles.splitter}>:</p>;
              else return <p>{item}</p>;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ido;

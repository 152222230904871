function formatStringToTwoDecimals(numericString) {
  const dotIndex = numericString.indexOf('.');
  if (dotIndex !== -1) {
    const wholePart = numericString.slice(0, dotIndex);
    let decimalPart = numericString.slice(dotIndex + 1);

    if (decimalPart.length > 2) {
      decimalPart = decimalPart.slice(0, 2); // Truncate decimal part to two decimal places
    }

    return `${wholePart}.${decimalPart}`;
  }

  return numericString; // Return as is if no decimal point found
}

function convertToTrillionRepresentation(numberString) {
  // Parse the numeric string to a number
  const number = parseFloat(numberString);

  // Convert to trillion representation
  const trillionRepresentation = number / 1e12;

  return trillionRepresentation;
}

export { formatStringToTwoDecimals, convertToTrillionRepresentation };

import React, { createContext, useState } from "react";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [tokenInfo, setTokenInfo] = useState({
    name: "",
    symbol: "",
    totalSupply: "",
    decimals: "",
  });
  return (
    <AppContext.Provider value={{ tokenInfo, setTokenInfo }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

import React, { useEffect, useRef, useState } from 'react';
import styles from './Roadmap.module.css';
import styles2 from '../Ido/Ido.module.css';
import phase1 from '../../assets/images/phase1.png';
import phase2 from '../../assets/images/phase2.png';
import phase3 from '../../assets/images/phase3.png';
import phase4 from '../../assets/images/phase4.png';
import phase5 from '../../assets/images/phase5.png';
import phase6 from '../../assets/images/phase6.png';
import phase7 from '../../assets/images/phase7.png';
import phase8 from '../../assets/images/phase8.png';

const roadmap = [
  { phase: 'Phase 1', text: 'Technical Readiness and Smart Contract Audit', src: phase1 },
  { phase: 'Phase 2', text: 'Increase Liquidity and Trading Volume', src: phase2 },
  { phase: 'Phase 3', text: 'Community Building and Marketing', src: phase3 },
  { phase: 'Phase 4', text: 'Ecosystem Expansion and Partnerships', src: phase4 },
  { phase: 'Phase 5', text: 'Savior NFT will be Airdropped', src: phase5 },
  { phase: 'Phase 6', text: 'Savior plan will be launched & multi-chain airdrops will save MEME coins and the community in the quagmire', src: phase6 },
  { phase: 'Phase 7', text: 'Market Research and Concept Validation, Commence Development of Savior GameFi', src: phase7 },
  { phase: 'Phase 8', text: 'Become a MEME market maker', src: phase8 },
];

const Roadmap = () => {
  const [cards, setCards] = useState(roadmap);

  useEffect(() => {
    // Set an interval to scroll automatically
    const interval = setInterval(() => {
      setCards((prev) => {
        const first = prev.shift();
        const new_coins = [...prev, first];
        return new_coins;
      });
    }, 3000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  return (
    <section className={[styles2.container, styles.container].join(' ')} id="roadmap">
      <h1 className="heading">Roadmap</h1>

      <div className={styles.roadmap}>
        {cards.map((item) => (
          <div className={styles.card} key={item.phase}>
            <img src={item.src} alt="" className={styles.image} />
            <span>
              <h4>{item.phase}</h4>
              <p>{item.text}</p>
            </span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Roadmap;

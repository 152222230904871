import React from 'react';
import styles from './Top.module.css';

const Top = () => {
  return (
    <section className={styles.top} id="home">
      <h1>Where Blockchain Heroes Thrive, Rescuing Dreams, Elevating Fortunes.</h1>
    </section>
  );
};

export default Top;

function getTimeLeft(target) {
  const now = new Date().getTime();
  const difference = target - now;

  if (difference <= 0) {
    return '00:00:00';
  }

  const totalSeconds = Math.floor(difference / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
}

function padZero(value) {
  return value.toString().padStart(2, '0');
}

export { getTimeLeft, padZero };

import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './FAQBox.module.css'; // Import your CSS module

const FAQBox = ({ number, heading, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.faqBox} data-aos="flip-down">
      <div className={styles.question} onClick={toggleOpen}>
        <div className={styles.number}>
          <p>{number}</p>
        </div>
        <div className={styles.heading}>
          <p>{heading}</p>
        </div>
        <div className={styles.icon}>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</div>
      </div>
      {isOpen && <div className={styles.answer}>{answer}</div>}
    </div>
  );
};

export default FAQBox;

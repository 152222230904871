import React, { useState } from 'react';
import styles from './Footer.module.css';
import footer_logo from '../../assets/images/footer_logo.png';
import { AiOutlineTwitter } from 'react-icons/ai';
import { FaTelegramPlane } from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io';
import { BsChevronDown } from 'react-icons/bs';
import useNotyf from '../../hooks/useNotyf';

const options = [
  { value: '', label: 'Coming Soon' },
  { value: 'pledge', label: 'Pledge' },
  { value: 'nft', label: 'Nft' },
];

const Footer = ({ scrollToSection }) => {
  const [selected, setSelected] = useState(options[0]);
  const [openOptions, setOpenOptions] = useState(false);
  const notyf = useNotyf();

  return (
    <footer className={styles.container}>
      <img src={footer_logo} alt="" />
      <h3>SITEMAP</h3>

      <div className={styles.sections}>
        <p onClick={() => scrollToSection('home')}>Home</p>
        <p onClick={() => scrollToSection('ido')}>IDO</p>
        <p onClick={() => window.open('https://savior-lottery-yraz.vercel.app/','_blank')}>Lottery</p>
        <p onClick={() => scrollToSection('tokenomics')}>Tokenomics</p>
        <p onClick={() => scrollToSection('roadmap')}>Roadmap</p>
        {/* <select value={''}>
          <option value="" selected>
            Coming Soon
          </option>
          <option value="pledge">Pledge</option>
          <option value="nft">Nft</option>
        </select> */}
        <div onClick={() => setOpenOptions((prev) => !prev)} className="select">
          {options[0].label} <BsChevronDown />
          {openOptions && (
            <div className="options" onClick={() => notyf.success('Coming Soon')}>
              {options
                .filter((option) => option.value !== selected.value)
                .map((option) => (
                  <p>{option.label}</p>
                ))}
            </div>
          )}
        </div>
      </div>

      <div className={styles.socials}>
        <AiOutlineTwitter fill="#ed0137" size={25} onClick={() => window.open('https://twitter.com/savior_svr', '_blank')} />
        <FaTelegramPlane fill="#ed0137" size={25} onClick={() => window.open('https://t.me/Savior_SVR', '_blank')} />
        <IoIosMail fill="#ed0137" size={25} onClick={() => window.open('mailto:savior.bsc@gmail.com', '_blank')} />
      </div>
    </footer>
  );
};

export default Footer;

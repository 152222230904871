import React from 'react';
import sword from '../../assets/images/sword.png';
import styles from './Faq.module.css';
import styles2 from '../Ido/Ido.module.css';
import FAQBox from '../FAQBox/FAQBox';

const Faq = () => {
  return (
    <section className={[styles2.container, styles.container].join(' ')}>
      <h1 className="heading">FAQ</h1>

      <div className={styles2.wrapper}>
        <div>
          <img src={sword} alt="sword" />
        </div>
        <div className={styles.faqs}>
          <FAQBox
            number="1"
            heading={`Why choose "Savior"?`}
            answer={`The name "Savior" was chosen because it conveys the core goals and vision of the project. "Savior" implies a rescuer or a savior, suggesting that the mission of this project is to save people's financial situations and create opportunities for success through innovative token mechanisms and collective efforts. The name emphasizes the project's vision of enabling everyone to share wealth and achieve success through blockchain technology and a sustainable ecosystem. Choosing "Savior" as the project's name pays tribute to the pioneers who have made efforts in this field but have not achieved success and encourages people to join the ranks of saving the future.`}
          />
          <FAQBox
            number="2"
            heading={`Why is the IDO duration so long?`}
            answer={`The extended IDO duration is chosen to launch at a specific market timing to maximize investor attraction and enhance the project's visibility. If the project receives widespread attention and demand during the IDO, it can more effectively distribute tokens. Additionally, it allows the partners who are driving the IDO to have more time to earn commissions. Furthermore, a longer IDO duration provides the project team with more opportunities to interact with the community, improve and optimize the product to better meet user needs Of course, according to the data, the project may end the IDO ahead of time and go public.`}
          />
          <FAQBox
            number="3"
            heading={`When will trading go live?`}
            answer={`Savior Token ($SVR) will be listed on decentralized exchanges (DEX). Our goal is to ensure listing on centralized exchanges (CEX), which will further enhance token liquidity and accessibility. The specific timing for trading to go live will depend on project progress and market conditions, and the team will promptly announce relevant updates to the community. Please stay tuned to our social media and official channels for the latest developments.`}
          />
          <FAQBox number="4" heading={`When can we claim the tokens?`} answer={`After the IDO concludes, you can claim the tokens on our official website.`} />
        </div>
      </div>
    </section>
  );
};

export default Faq;

import React, { useState } from 'react';
import samurai from '../../assets/images/intro_samurai.png';
import styles from './Intro.module.css';
import { BsChevronDown } from 'react-icons/bs';
import downArrow from '../../assets/images/downArrow.png';

const options = [
  { value: '', label: 'Documents' },
  { value: 'whitepaper', label: 'Whitepaper', link: 'https://thematrixlabs.gitbook.io/whitepaper-savior/' },
  { value: 'audit', label: 'Audit', link: 'https://app.solidproof.io/projects/savior' },
];

const Intro = () => {
  const [readMore, setReadMore] = useState(false);
  const [selected, setSelected] = useState(options[0]);
  const [openOptions, setOpenOptions] = useState(false);

  return (
    <section className={styles.container}>
      <div data-aos="fade-up">
        <h1 className="heading">Introduction</h1>
        <img src={samurai} alt="samurai" />
        <p>
          We've all been in the mud once, and now we've decided to fight it out.
          <br />
          Pay tribute to those pioneers of WEB3 and the warriors who dedicated their love to the blockchain. Save the lucky ones alive, join us to save the future!
          <br />
          Our mission is to empower everyone to share wealth and succeed.
          <br />
          {!readMore && <span onClick={() => setReadMore(true)}>read more...</span>}
          {readMore && (
            <p className={styles['read-more']}>
              We believe that through joint efforts and an innovative token mechanism, we can build an ecosystem where everyone can make money. Whether you are an investor, a liquidity provider or a
              user participating in IDO, you will receive continuous benefits and incentives. We sincerely invite you to join us and create a future of wealth and success together!
              <br />
              <br />
              ---- You won't die if you fall into the water, you will die if you stay in the water
            </p>
          )}
          {readMore && <span onClick={() => setReadMore(false)}>read less</span>}
        </p>
        {/* <select>
          <option value="">Documents</option>
          <option value="whitepaper">Whitepaper</option>
          <option value="audit">Audit</option>
          <option value="kyc">Kyc</option>
        </select> */}
        <div onClick={() => setOpenOptions((prev) => !prev)} className="select">
          {options[0].label} <BsChevronDown />
          {openOptions && (
            <div className="options">
              {options
                .filter((option) => option.value !== selected.value)
                .map((option) => (
                  <p onClick={() => window.open(option.link, '_blank')}>{option.label}</p>
                ))}
            </div>
          )}
        </div>
      </div>
      <div>
        <img src={samurai} alt="samurai" />
      </div>
    </section>
  );
};

export default Intro;

import './App.css';
import 'notyf/notyf.min.css';
import '@rainbow-me/rainbowkit/styles.css';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, polygon, bsc, bscTestnet } from 'wagmi/chains';
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { publicProvider } from 'wagmi/providers/public';
import { getDefaultWallets, connectorsForWallets } from '@rainbow-me/rainbowkit';
import Top from './components/Top/Top';
import Navbar from './components/Navbar/Navbar';
import Intro from './components/Intro/Intro';
import Ido from './components/Ido/Ido';
import Tokenomics from './components/Tokenomics/Tokenomics';
import Roadmap from './components/Roadmap/Roadmap';
import Faq from './components/Faq/Faq';
import Footer from './components/Footer/Footer';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS CSS file

const projectId = process.env.REACT_APP_PROJECT_ID;

const { chains, provider } = configureChains([bsc, bscTestnet, mainnet, polygon], [publicProvider()]);

const { wallets } = getDefaultWallets({
  appName: 'My RainbowKit App',
  projectId,
  chains,
});

const connectors = connectorsForWallets([...wallets]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: false,
      startEvent: 'scroll',
    });
  }, []);

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          initialChain={56}
          chains={chains}
          theme={darkTheme({
            accentColor: 'linear-gradient(125deg, #ED0137 20.07%, #F05733 75.59%)',
            accentColorForeground: 'white',
            overlayBlur: 'small',
            borderRadius: 'medium',
          })}
        >
          <Navbar scrollToSection={scrollToSection} />
          <Top />
          <Intro />
          <Ido />
          <Tokenomics />
          <Roadmap />
          <Faq />
          <Footer scrollToSection={scrollToSection} />
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;

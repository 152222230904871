import React, { useContext } from 'react';
import tokenomics from '../../assets/images/tokenomics.png';
import tokenomics_mobile from '../../assets/images/tokenomics_mobile.png';
import styles2 from '../Ido/Ido.module.css';
import styles from './Tokenomics.module.css';
import { AppContext } from '../../context/AppContext';
import { formatStringToTwoDecimals, convertToTrillionRepresentation } from '../../utils/format';

const Tokenomics = () => {
  const { tokenInfo } = useContext(AppContext);

  return (
    <section className={[styles2.container, styles.container].join(' ')} id="tokenomics">
      <h1 className="heading">Tokenomics</h1>

      <div className={[styles2.wrapper, styles.wrapper].join(' ')}>
        <div className={[styles2.tokenInfo, styles.tokenInfo].join(' ')} data-aos="fade-up-right">
          <span>Token Details</span>

          <p>
            Name <span>{tokenInfo.name||""}</span>
          </p>
          <p>
            Symbol <span>{tokenInfo.symbol||""}</span>
          </p>
          <p>
            Total Supply <span>{convertToTrillionRepresentation(tokenInfo.totalSupply)||0} Trillion</span>
          </p>
          <p>
            Decimals <span>{tokenInfo.decimals||0}</span>
          </p>
        </div>
        <div>
          <img src={tokenomics} alt="tokenomics" className={styles.tokenomics} />
          <img src={tokenomics_mobile} alt="tokenomics_mobile" className={styles.tokenomics_mobile} />
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
